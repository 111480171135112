import { GetWorkspaceDataDocument } from './use-workspace-data.generated'

export default function (workspaceId: Ref<string>) {
  const { data: workspace } = useQuery({
    query: GetWorkspaceDataDocument,
    variables: computed(() => ({ workspaceId: workspaceId.value })),
    tags: [`workspace-${workspaceId}`],
  }, ({ data }) => data?.workspace)

  return { workspace }
}
